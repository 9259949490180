<template>
    <div class="relative">
        <div class="w-full absolute block z-50">
            <Header/>
        </div>
        <div class="about-banner-container space-top w-full">
            <div class="relative h-full w-full flex justify-center flex-col">
                <img class="absolute top-0 right-0 h-full object-cover z-5 lg:mr-8" src="../../../assets/images/about-us/about-banner.png" alt="About Us Banner">
                <div class="flex flex-col -mb-6 mobileL:mb-0 z-10 max-w-xs md:max-w-xl 2xl:max-w-3xl p-0 mobileL:p-4 text-start mobileL:text-center ml-0 lg:ml-16 2xl:ml-20">
                    <h1 class="about-banner-title flex mobileL:static mobileL:justify-center ml-6 mobileS:text-2xl xs:text-3xl md:text-4xl lg:text-6xl 2xl:text-8xl text-white-1">
                        About Us
                    </h1>
                    <p class="mobileL:mt-4 mt-2 text-base md:text-xl lg:text-xl xl:text-2xl 2xl:text-4xl ml-2 px-4 mr-6 mobileL:mr-0 text-white-1 leading-snug">
                        We believe that having smooth, hair-free skin is the first step toward your best self. 
                        With Lay Bare, we've elevated the experience - making it affordable, accessible and more comfortable.
                    </p>
                </div>
            </div>
        </div>

        <div class="relative">
            <img class="absolute inset-0 w-full h-full object-cover" src="../../../assets/images/home-page/plc-perks/update-bg.png" alt="Background Image">
            <div class="relative top-0 left-0 w-full h-full flex items-center justify-center flex-col py-4 lg:py-8">
                <div class="text-white-1 md:text-sm lg:text-xl xl:text-2xl 2xl:text:4xl w-5/6 mobileL:w-3/4 text-center mt-8 leading-tight">
                    <p class="-my-6 md:-my-6 lg:-my-8" ><span class="font-tahu text-3xl md:text-5xl lg:text-7xl 2xl:text-9xl leading-none">Lay Bare</span> is the premier waxing expert in the Philippines. We offer hair- </p>
                    <p class="mt-6">removal services from brow-to-toe using all natural ingredients.<br><br>
                        At Lay Bare, we want to inspire beautiful skin as well as beautiful days through our products and services.<br><br>
                        Our ultimate goal is to empower both men and women to look and feel their best become brave and confident.
                    </p>
                </div>
            </div>
        </div>

        <div class="relative">
            <img class="absolute inset-0 w-full h-full object-cover" src="../../../assets/images/about-us/body-bg.png" alt="Background Image">
            <div class="relative">
                <div class="relative flex flex-col mobileL:flex-row items-center justify-center mobileL:gap-12 pt-2 mobileL:pt-0">
                    <img class="mr-4 w-1/2 mobileL:w-1/3 my-4 mobileL:my-12" src="../../../assets/images/about-us/history.png" alt="History">
                    <div class="w-4/5 mobileL:w-2/5 text-brown-4 text-center mobileL:text-left">
                        <h2 class="text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl font-semibold mb-4">Our History</h2>
                        <p class="text-lg md:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl leading-tight mobileL:leading-normal">
                            Lay Bare began as a family-owned salon in 2006 when three siblings founders Paolo, Fiona and Monique 
                            couldn't find a salon dedicated to the art of waxing. So, they set out to craft the perfect experience and 
                            introduced a new world of waxing possibilities for those who dared to bare it all.
                        </p>
                    </div>
                </div>
                <div class="relative flex flex-col-reverse mobileL:flex-row items-center justify-center mobileL:gap-12">
                    <div class="w-4/5 mobileL:w-2/5 text-brown-4 text-center mobileL:text-right leading-tight mobileL:leading-normal">
                        <h2 class="text-3xl md:text-3xl lg:text-5xl 2xl:text-6xl font-semibold mb-4">It's all about the customer experience</h2>
                        <p class="text-lg md:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl">
                            From day one, we've been putting our values into action to delight our guests with an exceptional 
                            waxing experience. From the private, comfy service room to the after care we provide, our golie 
                            to elevate what we offer by making it accessible, affortable and comfortable.
                        </p>
                    </div>
                    <img class="mr-4 w-1/2 mobileL:w-1/3 my-4 mobileL:my-12" src="../../../assets/images/about-us/customer-exp.png" alt="History">
                </div>
                <div class="relative flex flex-col mobileL:flex-row items-center justify-center mobileL:gap-12 pb-6 mobileL:pb-0">
                    <img class="mr-4 w-1/2 mobileL:w-1/3 my-4 mobileL:my-12" src="../../../assets/images/about-us/commited.png" alt="History">
                    <div class="w-4/5 mobileL:w-2/5 text-brown-4 text-center mobileL:text-left">
                        <p class="text-lg md:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl leading-tight mobileL:leading-normal">
                            We are strongly committed to lead through constant innovation and care for the environment. 
                            Each service is delighted and executed with the highest standards and the goal of creating 
                            on extraordinary experience, resulting in happy customers eager to partake another Hair Free Morning encounter. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full absolute block z-20">
            <Footer/>
        </div>
    </div>
</template>

<script>
import Header from '../../../components/navigation/LaybareHeader.vue'
import Footer from '../../../components/footer/LaybareFooter.vue'

export default {
  components: {
    Header,
    Footer
  },

  mounted() {
    this.$gtag.pageview(this.$route);
  },
}
</script>

<style>
.about-banner-container {
    height: 700px;
    background-size: cover;
    background: linear-gradient(180deg, #F2D0B7 0%, #D9AC8D 100%);
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .about-banner-container {
        height: 45dvh;
    }
}

.space-top {
  margin-top: 90px;
}

@media (min-width: 1280px) {
    .space-top {
        margin-top: 100px;
    }
}

@media (min-width: 1536px) {
    .about-banner-container {
      height: 110vh;
    }
    .space-top {
        margin-top: 128px;
    }
}

@media (max-width:480px) {
    .about-banner-container{
        height:255px;
    }
    .space-top {
    margin-top: 60px;
    }
}

.about-banner-title {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 0px #C58965;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: 600;
}

@media only screen and (min-width: 768px) {
    .about-banner-title {
        text-shadow: 0px 6px 0px #C58965;
    }
}

@media only screen and (min-width: 992px) {
    .about-banner-title {
        text-shadow: 0px 8px 0px #C58965;
    }
}

@media only screen and (min-width: 1200px) {
    .about-banner-title {
        text-shadow: 0px 10px 0px #C58965;
    }
}
</style>
